import React from "react"
import {Link} from "gatsby"
// import PostCard from "../../components/postCard.js"
import styled from "styled-components"
import {NavBar} from "../../../components/navbar";
import {GlobalStyle} from "../../../components/global-style";

const PostCard = ({post}) => (
    <Link className="post" to={post.fields.slug}>
        <p className="title">{post.frontmatter.title}</p>
        <p className="details">{post.frontmatter.date}</p>
    </Link>
)

const StyledBlog = styled.div`
  .content-container {
    width: 900px;
    max-width: 95%;
    margin: 0 auto;
    padding: 0 2.5%;
  }

  .hero {
    // background: blue;
    padding: 3em 0;
    width: 100%;
    border-bottom: solid gray 1px;
    margin-bottom: 3em;

    .hero-text {
      margin: 0;
      padding: 0;
      width: 95%;
      max-width: 900px;
    }
  }

  a.post {
    display: block;
    color: black;
    margin: 20px 0;
    text-decoration: none !important;

    &:hover {
      p.title {
        text-decoration: underline;
      }
    }

    p.details {
      margin: 0;
      color: gray;
    }
  }
`;

export default function ProfilePage({params}) {

    return <StyledBlog>
        <GlobalStyle/>
        <title>{params.namespace}</title>
        <NavBar/>
        <div className="content-container">
            <div className="hero">
                <div className="hero-text">
                    <h1>Ge Yang</h1>
                    <h2>{params.namespace}</h2>
                    <h2>{params.project}</h2>
                </div>
            </div>
            <h2>Experiments</h2>
            {[]}
        </div>
    </StyledBlog>
}